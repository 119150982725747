/* eslint-disable no-console */
import React from "react";
import { Typography } from "antd";
import { useIntl } from "react-intl";
import { orderBy } from "lodash";
import { Tabs } from "../../atoms/Tabs/Tabs";
import { ImageGallery } from "../../molecules";
import { IImage, IPolicy } from "../../../common/typings";
import {
  formatReadableCity,
  hasTextIgnoringHtml,
} from "../../../common/util/util";
import { ICity83431 } from "../../../common/types";
import { PackageDetailsWrapper } from "./PackageViewFrontEnd.style";
const { TabPane } = Tabs;
const { Paragraph } = Typography;

export interface IPackageDetailsFrontendProps {
  description: string;
  images: IImage[];
  policies: IPolicy[];
  cities: ICity83431[];
}

export const PackageDetail: React.FC<IPackageDetailsFrontendProps> = ({
  description,
  images,
  policies,
  cities,
}) => {
  const { formatMessage } = useIntl();
  console.log({ cities });
  return (
    <PackageDetailsWrapper>
      <ImageGallery images={images || []} />
      <Tabs defaultActiveKey="des" className="package-details-tabs">
        {hasTextIgnoringHtml(description) && (
          <TabPane tab={"Πρόγραμμα"} key="des">
            <div
              style={{ marginTop: 20 }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </TabPane>
        )}
        <TabPane
          tab={formatMessage({
            description: "Descriptions",
            defaultMessage: "Descriptions",
            id: "R3X6Pj",
          })}
          key="des1"
        >
          {cities.map((dis: ICity83431) => (
            <div key={dis.id}>
              {cities.length > 1 && (
                <div className="package-hotels-title">
                  {formatReadableCity(dis)}
                </div>
              )}
              <div
                style={{ marginTop: 20 }}
                dangerouslySetInnerHTML={{
                  __html: dis?.description || "",
                }}
              />
            </div>
          ))}
        </TabPane>
        {orderBy(policies, "orderId")?.map((policy, index) => (
          <TabPane tab={policy.name} key={`${index}`}>
            <Paragraph>
              <div
                style={{ marginTop: 20 }}
                dangerouslySetInnerHTML={{
                  __html: policy?.description || "",
                }}
              />
            </Paragraph>
          </TabPane>
        ))}
      </Tabs>
    </PackageDetailsWrapper>
  );
};
