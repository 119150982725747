/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useDispatch } from "react-redux";
import { Button, Empty, List, Skeleton } from "antd";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { chain, includes, Many, some } from "lodash";
import {
  FilterOutlined,
  SearchOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { defineMessage, FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";
import { HotelsFilters } from "../../molecules/Hotel/HotelsFilters";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchAllHotelsExclusiveOfferFromFirestore } from "../../../firestore/firestoreService/Hotel28355Store";
import { IExclusiveHotel64774, IHotel28355 } from "../../../common/types";
import { HotelItem, HotelRequestForm } from "../../molecules";
import { urlStringToOBject, delay } from "../../../common/util/util";
import { Select } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { fetchCity83431FromFirestore } from "../../../firestore/firestoreService";
import { HotelWrapper } from "./Hotel.style";
const partnerId = process.env.REACT_APP_SITE_ID || "";
const sortOptions = [
  {
    label: defineMessage({
      description: "Package Name A - Z",
      defaultMessage: "Package Name A - Z",
      id: "j0EK1D",
    }),
    value: "name-asc",
  },
  {
    label: defineMessage({
      description: "Package Name Z - A",
      defaultMessage: "Package Name Z - A",
      id: "OB23fp",
    }),
    value: "name-desc",
  },
];
const defaultSorting: any = {
  type: "sorting",
  name: "name",
  order: "asc",
  opr: "asc",
};
export const HotelList: React.FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [showRequestForm, setShowRequestForm] = React.useState<boolean>(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const params = urlStringToOBject(location.search);
  const [showFilter, setShowFilters] = React.useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const [sorting, setSorting] = React.useState<any>(defaultSorting);
  const [cities, setCities] = React.useState([]);
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [currentHotels, setCurrentHotels] = React.useState<IHotel28355[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [hotels, setHotels] = React.useState<IHotel28355[]>([]);
  useFirestoreCollection({
    query: () =>
      fetchAllHotelsExclusiveOfferFromFirestore(
        [
          {
            type: "filter",
            name: "exclusiveOffer",
            opr: "==",
            value: true,
          },
        ],
        [
          {
            type: "filter",
            name: "status",
            opr: "==",
            value: true,
          },
          {
            type: "filter",
            name: "partnerId",
            opr: "array-contains",
            value: partnerId,
          },
        ],
      ),
    data: (data: any) =>
      setHotels(
        data
          ?.map((hot: IHotel28355) => ({
            ...hot,
            exclusiveOffers: hot.exclusiveOffers
              ?.filter(
                (v: IExclusiveHotel64774) =>
                  !moment(v.periodEndDate).isBefore(moment()),
              )
              .filter((v: any) => {
                if (
                  queryParams.get("period") &&
                  queryParams.get("period") !== "all"
                ) {
                  if (Array.isArray(v.periodType)) {
                    return some(v.periodType, (pt: any) =>
                      queryParams.get("period")?.split("-").includes(pt),
                    );
                  }
                  return includes(
                    queryParams.get("period")?.split("-"),
                    v.periodType,
                  );
                }
                return true;
              }),
          }))
          .filter((v: IHotel28355) => v.exclusiveOffers.length > 0),
      ),

    local: true,
    deps: [dispatch, location],
    dataReady: true,
  });

  useFirestoreCollection({
    query: () =>
      fetchCity83431FromFirestore([
        {
          type: "filter",
          name: "countryCode",
          opr: "==",
          value: "CY",
        },
      ]),
    data: (data: any) =>
      setCities(data.map((v: any) => ({ value: v.code, label: v.name }))),
    local: true,
    deps: [dispatch],
  });
  const handelShowFilter = () => {
    setShowFilters((v) => !v);
  };
  const handleChangeSearch = () => {
    dispatch(
      openModal({
        modalType: "HotelsSearchModal",
        modalProps: { title: "Νέα αναζήτηση", params },
      }),
    );
  };
  const handelSortingChange = (value: string) => {
    const [name, direction]: [string, Many<boolean | "asc" | "desc">] =
      Object.values(value.split("-")) as [
        string,
        Many<boolean | "asc" | "desc">,
      ];
    setSorting({ type: "sorting", name, opr: "", order: direction });
  };
  React.useEffect(() => {
    setLoading(true);
    // const queryParams = new URLSearchParams(location.search);
    const currentMaxCapacity =
      +(queryParams.get("adultNumbers") || 0) +
      +(queryParams.get("childrenNumber") || 0);
    const defaultHotels = chain(hotels)
      .orderBy(sorting.name, sorting.order)
      .filter((p: any) => {
        if (queryParams.get("city") && queryParams.get("city") !== "all") {
          return includes(queryParams.get("city")?.split("-"), p?.city?.code);
        }
        return true;
      })
      .filter((p: any) => {
        // Extract the period only once for performance and clarity
        const period = queryParams.get("period");
        const periodsToCheck: string[] = period?.split("-") || [];

        // If there's no period specified or the period is 'all', return true for all items
        if (!period || period === "all") {
          return true;
        }

        const dd = some(p?.exclusiveOffers, (offer: any) => {
          const { periodType } = offer;
          console.log({ periodType });
          if (Array.isArray(periodType)) {
            return some(periodType, (pt: any) => {
              console.log({ pt, periodsToCheck });
              return periodsToCheck.includes(pt);
            });
          }
          return periodsToCheck.includes(periodType);
        });
        console.log({ dd });
        return dd;
      })
      .filter((p: any) => {
        if (queryParams.get("adultNumbers")) {
          return some(
            p?.exclusiveOffers,
            (el: IExclusiveHotel64774) =>
              +(queryParams.get("adultNumbers") || 0) <=
              +(el.maxNumberOfAdults || 0),
          );
        }
        return true;
      })
      .filter((p: any) => {
        if (queryParams.get("childrenNumber")) {
          return some(
            p?.exclusiveOffers,
            (el: IExclusiveHotel64774) =>
              +(queryParams.get("childrenNumber") || 0) <=
              +(el.maxNumberOfChildren || 0),
          );
        }
        return true;
      })
      .filter((p: any) => {
        if (queryParams.get("infant")) {
          return some(
            p?.exclusiveOffers,
            (el: IExclusiveHotel64774) =>
              +(queryParams.get("infant") || 0) <=
              +(el.maxNumberOfInfants || 0),
          );
        }
        return true;
      })
      .filter((p: any) => {
        if (currentMaxCapacity) {
          return some(
            p?.exclusiveOffers,
            (el: IExclusiveHotel64774) =>
              +currentMaxCapacity <= +(el.maxCapacity || 0),
          );
        }
        return true;
      })
      .value();

    if (queryParams.get("hotel-id")) {
      const selectedHotel: any = hotels.find(
        (v) => v.id === queryParams.get("hotel-id"),
      );
      if (selectedHotel) {
        defaultHotels.unshift(selectedHotel);
      }
    }
    setCurrentHotels(defaultHotels);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    delay(500).then(() => setLoading(false));
    setShowRequestForm(true);
  }, [location.search, sorting, hotels]);
  return (
    <HotelWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Poupas for holidays - Hotels list`}</title>
      </Helmet>
      <div className="hotel">
        <div className="hotel-body">
          <div
            className={classNames("hotel-left", {
              hide: isTabletOrMobile ? !showFilter : showFilter,
            })}
          >
            <HotelsFilters
              showFilter={handelShowFilter}
              isTabletOrMobile={isTabletOrMobile}
              params={params}
              url={"hotels"}
              hotelCities={cities}
              hotels={hotels}
            />
          </div>

          <div className="hotel-main">
            <div className="hotel-header">
              {isTabletOrMobile && (
                <div className="hotel-header-filter">
                  <Button type="link" onClick={handelShowFilter}>
                    <FilterOutlined />
                  </Button>
                </div>
              )}
              <div className="hotel-header-result">
                {" "}
                {currentHotels?.length || 0}{" "}
                <FormattedMessage
                  description="Results"
                  defaultMessage="Results"
                  id="r1l+Iq"
                />
                <Button
                  onClick={handleChangeSearch}
                  type="primary"
                  className="btn"
                >
                  {!isMobile ? "Νέα αναζήτηση" : <SearchOutlined />}
                </Button>
              </div>
              <div className="hotel-header-sorting">
                <span className="title">
                  {!isMobile ? (
                    <FormattedMessage
                      description="Sorting"
                      defaultMessage="Sorting"
                      id="WwgJXV"
                    />
                  ) : (
                    <SortAscendingOutlined />
                  )}
                </span>
                {!isMobile && (
                  <Select
                    defaultValue="name-asc"
                    options={sortOptions.map((v) => ({
                      label: formatMessage(v.label),
                      value: v.value,
                    }))}
                    value={`${sorting.name}-${sorting.order}`}
                    onChange={handelSortingChange}
                  />
                )}
              </div>
            </div>
            <div className="hotel-result">
              <List
                dataSource={currentHotels}
                renderItem={(item) => (
                  <Skeleton loading={loading} active>
                    <HotelItem
                      hotelId={item.id}
                      hotel={item}
                      isTabletOrMobile={isTabletOrMobile}
                      filter={params}
                    />
                  </Skeleton>
                )}
                locale={{
                  emptyText: (
                    <Empty description={"No Hotel available"}>
                      {hotels?.length > 0 && showRequestForm && !loading && (
                        <HotelRequestForm />
                      )}
                    </Empty>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </HotelWrapper>
  );
};
