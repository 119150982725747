import { Empty, List, Radio, Skeleton } from "antd";
import { chain, includes, some } from "lodash";
import React from "react";
import moment from "moment";
import { useIntl, FormattedMessage, defineMessage } from "react-intl";
import { FilterOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { IPackage } from "../../../common/types";
import { delay } from "../../../common/util/util";
import { Button, Select } from "../../atoms";
import { travelerTypeOptions } from "../../../enums";
import PackageItem from "./PackageItem";
import { PackageRequestForm } from "./PackageRequestForm";
const sortOptions = [
  {
    label: defineMessage({
      description: "Price Low to High",
      defaultMessage: "Price Low to High",
      id: "cVXf/g",
    }),
    value: "minPricePerAdult-asc",
  },
  {
    label: defineMessage({
      description: "Price High to Low",
      defaultMessage: "Price High to Low",
      id: "8cF34B",
    }),
    value: "minPricePerAdult-desc",
  },
  {
    label: defineMessage({
      description: "Travel Date Ascending",
      defaultMessage: "Travel Date Ascending",
      id: "jB/cqi",
    }),
    value: "departureDate-asc",
  },
  {
    label: defineMessage({
      description: "Travel Date Descending",
      defaultMessage: "Travel Date Descending",
      id: "O08693",
    }),
    value: "departureDate-desc",
  },
  {
    label: defineMessage({
      description: "Package Name A - Z",
      defaultMessage: "Package Name A - Z",
      id: "j0EK1D",
    }),
    value: "name-asc",
  },
  {
    label: defineMessage({
      description: "Package Name Z - A",
      defaultMessage: "Package Name Z - A",
      id: "OB23fp",
    }),
    value: "name-desc",
  },
];
type DiscountType = "pasydyDiscount" | "poedDiscount";
const discountFieldName = process.env.REACT_APP_SITE_DISCOUNT as DiscountType;
export interface IPackageItemListProps {
  travelPackages: IPackage[];
  sorting: any;
  isTabletOrMobile: boolean;
  isMobile: boolean;
  onShowFilter: () => void;
  onSort: (value: string) => void;
  partner?: string;
}
export const PackageItemList: React.FC<IPackageItemListProps> = ({
  travelPackages,
  sorting,
  isTabletOrMobile,
  isMobile,
  onShowFilter,
  onSort,
  partner,
}) => {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const [currentPackages, setCurrentPackages] = React.useState<IPackage[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showRequestForm, setShowRequestForm] = React.useState<boolean>(false);
  const [interest, setInterest] = React.useState("");
  const handleChangeInterest = (value: any) => {
    setInterest(value?.target?.value || "");
  };
  const [discount] = React.useState<boolean>(false);
  React.useEffect(() => {
    setLoading(true);
    const queryParams = new URLSearchParams(location.search);
    const defaultPackages = chain(travelPackages)
      .map((p: any) => {
        const dis = +(p?.[discountFieldName] || 0);
        const currentDiscount: number = Math.ceil(
          (+p.minPricePerAdult * +dis) / 100,
        );
        const currentPrice = +p.minPricePerAdult - +currentDiscount;
        return {
          ...p,
          minPricePerAdult: currentPrice,
        };
      })
      .filter((p: any) => {
        if (queryParams.get("type") && queryParams.get("type") !== "all") {
          if (queryParams.get("type") === "ORGANIZED") {
            return p.organizedPackage;
          }
          return !p.organizedPackage;
        }
        return true;
      })
      .filter((p: any) => {
        if (queryParams.get("from") && queryParams.get("from") !== "all") {
          return includes(
            queryParams.get("from")?.split("_"),
            p.departureCity?.code,
          );
        }
        return true;
      })
      .filter((p: any) => {
        if (queryParams.get("to") && queryParams.get("to") !== "all") {
          return some(p.destinationCodes, (el) =>
            includes(queryParams.get("to")?.split("_"), el),
          );
        }
        return true;
      })
      .filter((p: any) => {
        if (queryParams.get("hotels") && queryParams.get("hotels") !== "all") {
          return some(p.hotels, (el) =>
            includes(queryParams.get("hotels")?.split("_"), el.hotelId),
          );
        }
        return true;
      })
      .filter((p: any) => {
        if (queryParams.get("when") && queryParams.get("when") !== "all") {
          return includes(
            queryParams.get("when")?.split("_"),
            moment(p.departureDate).format("MM-YYYY"),
          );
        }
        return true;
      })
      .filter((p: any) => {
        if (queryParams.get("price")) {
          const [min, max] = queryParams.get("price")?.split("_") || [];
          if (min && max) {
            return p.minPricePerAdult >= min && p.minPricePerAdult <= max;
          }
        }
        return true;
      })
      .filter((p: any) => {
        if (
          queryParams.get("category") &&
          queryParams.get("category") !== "all"
        ) {
          return some(p.categoryCodes, (el) =>
            includes(queryParams.get("category")?.split("_"), el),
          );
        }
        return true;
      })
      .orderBy(
        [(p) => (p.travelerType?.includes(interest) ? 1 : 0), sorting.name],
        ["desc", sorting.order],
      )
      .value();

    setCurrentPackages(defaultPackages);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    delay(500).then(() => setLoading(false));
    setShowRequestForm(true);
  }, [location, sorting, travelPackages, interest]);
  return (
    <div className="package-list-frontend-right">
      {discount && (
        <div className="package-list-frontend-discount-text">
          Προσφορά Μόνο για τη Black Friday! -10% σε όλα μας τα πακέτα{" "}
        </div>
      )}
      <div className="package-list-frontend-header">
        {isTabletOrMobile && (
          <div className="package-list-frontend-header-filter">
            <Button type="link" onClick={onShowFilter}>
              <FilterOutlined />
            </Button>
          </div>
        )}
        <div className="package-list-frontend-header-result">
          {" "}
          {currentPackages.length}{" "}
          <FormattedMessage
            description="Results"
            defaultMessage="Results"
            id="r1l+Iq"
          />
        </div>
        <div className="package-list-frontend-header-sorting">
          <span className="title">
            {!isMobile ? (
              <FormattedMessage
                description="Sorting"
                defaultMessage="Sorting"
                id="WwgJXV"
              />
            ) : (
              <SortAscendingOutlined />
            )}
          </span>
          <Select
            defaultValue="minPricePerAdult-asc"
            options={sortOptions.map((v) => ({
              label: formatMessage(v.label),
              value: v.value,
            }))}
            value={`${sorting.name}-${sorting.order}`}
            onChange={onSort}
          />
        </div>
      </div>

      <div className="package-list-frontend-result">
        <div className="package-list-frontend-interest">
          <span className="package-list-frontend-interest-title">
            {" "}
            Ταξιδιωτικές Προτάσεις
          </span>

          <Radio.Group
            options={travelerTypeOptions}
            onChange={handleChangeInterest}
            optionType={"button"}
          />
        </div>
        <List
          dataSource={currentPackages}
          renderItem={(tavelPackage: IPackage) => (
            <Skeleton loading={loading} active>
              <PackageItem
                key={tavelPackage?.id}
                travelPackage={tavelPackage}
                isMobile={isMobile}
                partner={partner}
                selectedInterest={interest}
              />
            </Skeleton>
          )}
          locale={{
            emptyText: (
              <Empty
                description={formatMessage({
                  description: "No packages available",
                  defaultMessage: "No packages available",
                  id: "OIjx77",
                })}
              >
                {travelPackages.length > 0 && showRequestForm && (
                  <PackageRequestForm isMobile={isMobile} />
                )}
              </Empty>
            ),
          }}
        />
      </div>
    </div>
  );
};
