/* eslint-disable no-console */
import React from "react";
import moment from "moment";
import { Alert, Button, Divider, Form, Space } from "../../atoms";
import { InputField, InputTextAreaField } from "../commonFields";
import { InputDateRangeField } from "../commonFields/InputDateRangeField";
import { sendRequestPackageForm } from "../../../firestore/firestoreService";
import { PackageRequestFormWrapper } from "./PackageListFrontEnd.style";
interface IProps {
  isMobile: boolean;
}
export const PackageRequestForm: React.FC<IProps> = ({ isMobile }) => {
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [send, setSend] = React.useState(false);
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const values: any = await form.validateFields();
      const [startDate, endDate] = values.date;
      await sendRequestPackageForm({
        name: values.name || "",
        email: values.email || "",
        phoneNumber: values.phoneNumber || "",
        country: values.country || "",
        city: values.city || "",
        date: `${startDate ? moment(startDate).format("DD/MM/YYYY") : "-"}-${endDate ? moment(endDate).format("DD/MM/YYYY") : "-"}`,
        adults: values.adults || "",
        children: values.children || "",
        infants: values.infants || "",
        roomNumber: values.roomNumber || "",
        message: values.message || "",
        url: window?.location?.href || "",
      });
      form.resetFields();
      setSend(true);
      setLoading(false);
    } catch (errorInfo) {
      console.log(errorInfo);
      setLoading(false);
    }
  };
  return (
    <PackageRequestFormWrapper>
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        className="request-form"
        initialValues={{ adultNumbers: "2", childrenNumber: 0, infant: 0 }}
      >
        {send && <Alert message="Email Send Successfully" type="success" />}
        <div className="request-form-title">
          Δεν βρήκατε το πακέτο που σας ενδιαφέρει;
        </div>
        <div className="request-form-sub-title">
          Συμπληρώστε τη πιο κάτω φόρμα σύμφωνα με την αναζήτηση σας και θα
          επικοινωνήσουμε εμείς μαζί σας εντός 24 εργάσιμων ωρών για να σας
          βοηθήσουμε
        </div>
        <Divider>Στοιχεία Επικοινωνίας</Divider>
        <InputField
          label={"Ονοματεπώνυμο"}
          name="name"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        />
        <InputField
          label={"Email address"}
          name="email"
          placeholder="Email address"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        />
        <InputField
          label={"Τηλέφωνο"}
          name="phoneNumber"
          placeholder="Τηλέφωνο"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        />
        <Divider>Στοιχεία του πακέτου σας</Divider>
        <Space.Compact
          block
          direction={isMobile ? "vertical" : "horizontal"}
          style={{ gap: 20 }}
        >
          <InputField
            label={"Χώρα"}
            name="country"
            placeholder="Χώρα"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
            style={{ width: "100%" }}
          />
          <InputField
            label={"Πόλη"}
            name="city"
            placeholder="Πόλη"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
            style={{ width: "100%" }}
          />
        </Space.Compact>
        <InputDateRangeField
          label={"Ημερομηνίες"}
          name="date"
          placeholder="Ημερομηνίες"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
          style={{ width: "100%" }}
        />
        <Space.Compact style={{ gap: 20 }} block>
          <InputField
            label={"Ενήλικες"}
            name="adults"
            placeholder="Ενήλικες"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          />
          <InputField
            label={"Παιδιά"}
            name="children"
            placeholder="Παιδιά"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          />
          <InputField
            label={"Βρέφοι"}
            name="infants"
            placeholder="Βρέφοι"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          />
        </Space.Compact>
        <InputField
          label={"Αριθμός Δωματίων"}
          name="roomNumber"
          placeholder="Αριθμός Δωματίων"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        />
        <InputTextAreaField
          label={"Μήνυμα"}
          name="message"
          placeholder="Μήνυμα"
          style={{ width: "100%" }}
        />
        <Space style={{ width: "100%" }}></Space>
        <Button
          loading={loading}
          onClick={handleSubmit}
          className="request-form-button"
          type="primary"
          block
        >
          Υποβολή
        </Button>
      </Form>
    </PackageRequestFormWrapper>
  );
};
