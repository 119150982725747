/* eslint-disable no-console */
import { Space } from "antd/lib";
import React from "react";
import { UserOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import moment from "moment";
import { HotelRoomCartItemWrapper } from "../Hotel.style";
import {
  IHotelBookingPhysicalRoom,
  IRoom40231,
} from "../../../../common/types";
import { userFirestoreDoc } from "../../../../hooks/useFirestoreDoc";
import { listenToRoom40231FromFirestore } from "../../../../firestore/firestoreService";
import { mealTypes } from "../../../../enums";
const cancelationPolicy: any = {
  REFUNDABLE: "Με δωρεάν ακύρωση μέχρι",
  NON_REFUNDABLE: "Χωρίς δωρεάν ακύρωση",
};
function createArrayFromNumber(n: number) {
  return Array.from({ length: n }, (_, index) => index);
}
interface IProps {
  room: IHotelBookingPhysicalRoom;
  hotelId: string;
  removeRoom?: (id: string) => void;
  hotelName: string;
}
export const HotelRoomCartItem: React.FC<IProps> = ({
  room,
  removeRoom,
  hotelId,
  hotelName,
}) => {
  const dispatch = useDispatch();
  const [roomDetails, setRoom] = React.useState<IRoom40231>();
  userFirestoreDoc({
    query: () => listenToRoom40231FromFirestore(hotelId, room.roomId || ""),
    data: setRoom,
    shouldExecute: !!room.roomId && !!hotelId,
    deps: [dispatch, room.roomId],
    local: true,
  });
  const createGuestIcons = (guestNumber: number) =>
    createArrayFromNumber(guestNumber).map((i: number) => (
      <UserOutlined key={i} />
    ));
  const handleRemoveItem = () => {
    removeRoom && removeRoom(room.id);
  };
  return (
    <HotelRoomCartItemWrapper>
      {removeRoom && (
        <CloseCircleOutlined
          className="HotelRoomCartItem-remove"
          onClick={handleRemoveItem}
        />
      )}
      <span className="HotelRoomCartItem-price">{hotelName}</span>
      <Space>
        <span className="HotelRoomCartItem-roomNumber">{room.roomNumber}x</span>
        <span className="HotelRoomCartItem-name">{roomDetails?.name}</span>
        <div className="HotelRoomCartItem-guest">
          {createGuestIcons(room.adultNumber)}
        </div>
        <span className="HotelRoomCartItem-guest-child">
          {createGuestIcons(room.childNumber)}
          {+room.infantNumber === 1 && (
            <img
              src="/assets/baby-boy.png"
              alt=""
              style={{
                height: 12,
                marginLeft: room.childNumber > 0 ? 5 : 0,
              }}
            />
          )}
        </span>
        <span className="HotelRoomCartItem-price">
          €{Math.ceil(room.totalPrice)}
        </span>
      </Space>
      <div className="HotelRoomCartItem-mealType">
        Με {mealTypes[room.mealType]?.label || ""}
      </div>
      <div className="HotelRoomCartItem-refundable">
        {cancelationPolicy[room?.cancelationPolicy || ""] || ""}{" "}
        {room.cancelationPolicy
          ? `(${moment(room.refundableDate).format("DD/MM/YYYY")})`
          : ""}
      </div>
    </HotelRoomCartItemWrapper>
  );
};
