import React from "react";
import { CheckOutlined, RubyOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Button } from "../../atoms/Button/Button";
import { ImageGallery } from "../ImageGallery/ImageGallery";
import { HotelInfo } from "../Hotel/HotelInfo";
import { IPackageHotelPublic } from "../../../common/typings";
import { IPackage } from "../../../common/types";
import { PriceBox } from "../PriceBox/PriceBox";
import { Alert, Space } from "../../atoms";
import { formatReadableAddress } from "../../../common/util/util";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PackageHotelItemWrapper } from "./PackageHotelItem.style";

export interface IPackageHotelItemProps {
  packageHotel: IPackageHotelPublic;
  selectHotel: (hotel: IPackageHotelPublic) => void;
  isSelectedHotel: boolean;
  showHotel: (hotel: IPackageHotelPublic) => void;
  isTabletOrMobile: boolean;
  discount: number;
  selectedRooms: any;
  travelPackage: IPackage;
  setCityView?: () => void;
  multipleCity?: boolean;
  cityDisplayName: string;
}

const cancellationPolicy: any = {
  REFUNDABLE: "Με δωρεάν ακύρωση μέχρι",
  PARTIAL_REFUNDABLE: "Με δικαίωμα Μερικής Ακύρωσης μέχρι",
  NON_REFUNDABLE: "",
};
function canCalculateAllPrices(
  packageHotel: any,
  selectedRooms: any,
  multipleCity?: boolean,
) {
  if (multipleCity) {
    return true;
  }
  for (const { roomType, numberChilds } of selectedRooms) {
    // Determine the correct packageHotel key based on the roomType
    let roomPriceKey = "";
    switch (roomType) {
      case "double":
        roomPriceKey = "doubleRoomPricePublic";
        break;
      case "triple":
        roomPriceKey = "tripleRoomPricePublic";
        break;
      case "quadruple":
        roomPriceKey = "quadrupleRoomPricePublic";
        break;
      case "single":
        roomPriceKey = "singleRoomPricePublic";
        break;
      default:
        return false; // Unknown room type
    }
    if (packageHotel[roomPriceKey] === 0) {
      return false;
    }
    if (numberChilds > 2) {
      return false;
    }
    // If there's at least one child, check if child price is available
    if (numberChilds > 1 && packageHotel.secondChildPricePublic === 0) {
      return false;
    }
    if (numberChilds > 0 && packageHotel.firstChildPricePublic === 0) {
      return false;
    }
    if (["quadruple", "triple"].includes(roomType) && numberChilds > 0) {
      return false;
    }
  }
  return true;
}
export const PackageHotelItem: React.FC<IPackageHotelItemProps> = ({
  packageHotel,
  selectHotel,
  isSelectedHotel,
  showHotel,
  isTabletOrMobile,
  discount,
  selectedRooms,
  travelPackage,
  setCityView,
  multipleCity,
  cityDisplayName,
}) => {
  const dispatch = useDispatch();
  const [available, setAvailable] = React.useState(false);
  const { formatMessage } = useIntl();
  const handleSelectHotel = (value: any) => () => {
    selectHotel(value);
    setCityView && setCityView();
  };
  const handleSendRequest = () => {
    dispatch(
      openModal({
        modalType: "RequestPackageFormModal",
        modalProps: {
          title: "Φόρμα Επικοινωνίας",
          packageHotel,
          selectedRooms,
          travelPackage,
        },
      }),
    );
  };
  React.useEffect(() => {
    const isAvailable = canCalculateAllPrices(
      packageHotel,
      selectedRooms,
      multipleCity,
    );
    setAvailable(isAvailable);
  }, [selectedRooms, packageHotel]);
  return (
    <PackageHotelItemWrapper>
      <div>
        <ImageGallery
          images={packageHotel.hotel?.images || []}
          showThumbnails={false}
          showPlayButton={false}
        />
      </div>
      <div className="package-hotel-item-body">
        <div className="package-hotel-item-hotel">
          <HotelInfo
            starRating={packageHotel.hotel.starRating}
            hotelName={
              <Link to="#" onClick={() => showHotel(packageHotel)}>
                {packageHotel.hotel.name}
              </Link>
            }
            address={formatReadableAddress(packageHotel.hotel?.address) || ""}
            imageUrl={packageHotel.hotel?.defaultImage?.url || ""}
            mealType={packageHotel.mealsType}
            moreDetails={() => showHotel(packageHotel)}
          />
          {!isTabletOrMobile && available && (
            <div className="package-hotel-item-select">
              <Button
                onClick={handleSelectHotel({
                  ...packageHotel,
                  cityDisplayName,
                })}
                className={
                  isSelectedHotel ? "package-hotel-item-is-selected" : ""
                }
              >
                {" "}
                {isSelectedHotel && <CheckOutlined />}
                <FormattedMessage
                  description="Select"
                  defaultMessage="Select"
                  id="du8694"
                />
              </Button>
            </div>
          )}
        </div>

        <Space className="package-hotel-item-prices" align="baseline">
          {packageHotel.singleRoomPrice > 0 && (
            <PriceBox
              title={formatMessage({
                description: "Single Room",
                defaultMessage: "Single Room",
                id: "wya+Gv",
              })}
              price={packageHotel.singleRoomPricePublic}
              descriptions={packageHotel.mealsType}
              currency="€"
              discount={+(discount || 0)}
              isAddPrice={!!multipleCity}
            />
          )}
          <PriceBox
            title={formatMessage({
              description: "Double Room",
              defaultMessage: "Double Room",
              id: "SQOSAp",
            })}
            price={packageHotel.doubleRoomPricePublic}
            descriptions={packageHotel.mealsType}
            currency="€"
            discount={+(discount || 0)}
            isAddPrice={!!multipleCity}
          />
          {packageHotel.tripleRoomPrice > 0 && (
            <PriceBox
              title={formatMessage({
                description: "Triple Room",
                defaultMessage: "Triple Room",
                id: "PuM+Ls",
              })}
              price={packageHotel.tripleRoomPricePublic}
              descriptions={packageHotel.mealsType}
              discount={+(discount || 0)}
              currency="€"
              isAddPrice={!!multipleCity}
            />
          )}
          {packageHotel.quadrupleRoomPrice > 0 && (
            <PriceBox
              title={formatMessage({
                description: "Quadruple Room",
                defaultMessage: "Quadruple Room",
                id: "JVViU2",
              })}
              price={packageHotel.quadrupleRoomPricePublic}
              descriptions={packageHotel.mealsType}
              discount={+(discount || 0)}
              currency="€"
              isAddPrice={!!multipleCity}
            />
          )}
          {packageHotel.firstChildPrice > 0 && (
            <PriceBox
              title={formatMessage({
                description: "First Child",
                defaultMessage: "First Child",
                id: "1uxN4o",
              })}
              price={packageHotel.firstChildPricePublic}
              descriptions={packageHotel.mealsType}
              discount={+(discount || 0)}
              currency="€"
              isAddPrice={!!multipleCity}
            />
          )}
          {packageHotel.secondChildPrice > 0 && (
            <PriceBox
              title={formatMessage({
                description: "Second Child",
                defaultMessage: "Second Child",
                id: "xQsO3f",
              })}
              price={packageHotel.secondChildPricePublic}
              descriptions={packageHotel.mealsType}
              discount={+(discount || 0)}
              currency="€"
              isAddPrice={!!multipleCity}
            />
          )}
        </Space>
        {available && packageHotel.cancellationPolicy === "REFUNDABLE" && (
          <>
            <div className="package-hotel-item-payment">
              {" "}
              {travelPackage.allowPartialPayment &&
              travelPackage.cancellationPolicy !== "NON_REFUNDABLE"
                ? `Κρατήστε σήμερα με Προκαταβολή των €${travelPackage.partialPaymentPercent} ανά άτομο μόνο!`
                : ""}
            </div>
            <div className="package-hotel-item-policy">
              {cancellationPolicy[travelPackage.cancellationPolicy] || ""}{" "}
              {travelPackage.cancellationPolicy !== "NON_REFUNDABLE"
                ? `${moment(travelPackage.refundableDate).format("DD/MM/YYYY")}`
                : ""}
            </div>
          </>
        )}
        {available && packageHotel.cancellationPolicy !== "REFUNDABLE" && (
          <>
            <div className="package-hotel-item-payment">
              <RubyOutlined /> Μοναδική Προσφορά! Χωρίς Δυνατότητα Ακύρωσης.
            </div>
          </>
        )}
        {!isTabletOrMobile && !available && (
          <Alert
            style={{ padding: 10, margin: 10 }}
            message={
              <>
                <span>
                  Δυστυχώς τα δωμάτια του πακέτου δεν μπορούν να ικανοποιήσουν
                  την αναζήτηση σας.
                </span>{" "}
                <br />
                Μην ανησυχείτε όμως συμπληρώνοντας τη φόρμα αναζήτησης θα λάβετε
                νέα προσφορά προσαρμοσμένη στα μέτρα σας εντός 24 εργάσιμων
                ωρών.
              </>
            }
            type="warning"
            action={
              <Space>
                <Button size="small" type="primary" onClick={handleSendRequest}>
                  Στείλτε Αίτημα
                </Button>
              </Space>
            }
          />
        )}
        {isTabletOrMobile && available && (
          <div className="package-hotel-item-select">
            <Button
              onClick={handleSelectHotel({ ...packageHotel, cityDisplayName })}
              className={
                isSelectedHotel ? "package-hotel-item-is-selected" : ""
              }
            >
              {" "}
              {isSelectedHotel && <CheckOutlined />}
              <FormattedMessage
                description="Select"
                defaultMessage="Select"
                id="du8694"
              />
            </Button>
          </div>
        )}
      </div>
      {isTabletOrMobile && !available && (
        <Alert
          style={{ padding: 10, margin: 10 }}
          message={
            <>
              <span>
                Δυστυχώς τα δωμάτια του πακέτου δεν μπορούν να ικανοποιήσουν την
                αναζήτηση σας.
              </span>{" "}
              <br />
              Μην ανησυχείτε όμως συμπληρώνοντας τη φόρμα αναζήτησης θα λάβετε
              νέα προσφορά προσαρμοσμένη στα μέτρα σας εντός 24 εργάσιμων ωρών.
            </>
          }
          type="warning"
          action={
            <Space>
              <Button size="small" type="primary" onClick={handleSendRequest}>
                Στείλτε Αίτημα
              </Button>
            </Space>
          }
        />
      )}
    </PackageHotelItemWrapper>
  );
};
