/* eslint-disable no-console */
import React from "react";
import { FormattedMessage } from "react-intl";
import { IRoomInput } from "../../../common/typings";
import { Form, Space } from "../../atoms";
import { SelectField } from "../commonFields/SelectField";
import { TravellerSelectorsWrapper } from "./PackageViewFrontEnd.style";
export interface ITravellerSelectorsProps {
  setRooms: (Rooms: IRoomInput[]) => void;
  rooms: IRoomInput[];
  selectedHotels: (value: any) => void;
  isMobile: boolean;
  setCityIndex: (value: number) => void;
}

const roomsNumber = [
  { value: "1", label: "1 Δωμάτιο" },
  { value: "2", label: "2 Δωμάτια" },
  { value: "3", label: "3 δωμάτια" },
];
const roomType = (isMobile: boolean) => [
  {
    value: "single",
    label: isMobile ? "(1 Ενήλικας)" : "(1 Ενήλικας) Μονόκλινο",
  },
  {
    value: "double",
    label: isMobile ? "(2 Ενήλικες)" : "(2 Ενήλικες) Δίκλινο Δωμάτιο",
  },
  {
    value: "triple",
    label: isMobile ? "(3 Ενήλικες)" : "(3 Ενήλικες) Τρίκλινο Δωμάτιο",
  },
  {
    value: "quadruple",
    label: isMobile ? "(4 Ενήλικες)" : "(4 Ενήλικες) Τετράκλινο Δωμάτιο",
  },
];
const childernNumber = [
  { value: 0, label: "Χωρίς παιδί" },
  { value: 1, label: "1 παιδί" },
  { value: 2, label: "2 Παιδιά" },
  { value: 3, label: "3 Παιδιά" },
  { value: 4, label: "4 Παιδιά" },
];
// const isRoomTypeAvailable = (hotels: IPackageHotelPublic[], type: string) =>
//   some(hotels, [type, 0]);

export const TravelerSelectors: React.FC<ITravellerSelectorsProps> = ({
  setRooms,
  isMobile,
  selectedHotels,
  setCityIndex,
}) => {
  const [form] = Form.useForm();
  const handleChangeNumbersRooms = (value: string) => {
    const currentRooms: IRoomInput[] = [];
    if (value === "1") {
      currentRooms.push({ roomType: "double", numberChilds: 0 });
    } else if (value === "2") {
      currentRooms.push(
        { roomType: "double", numberChilds: 0 },
        { roomType: "double", numberChilds: 0 },
      );
    } else {
      currentRooms.push(
        { roomType: "double", numberChilds: 0 },
        { roomType: "double", numberChilds: 0 },
        { roomType: "double", numberChilds: 0 },
      );
    }
    form.setFieldsValue({ numberOfRooms: value, rooms: currentRooms });
    setRooms(currentRooms);
  };
  const handleOnValuesChange = (value: any, allValues: any) => {
    const selectedRooms: any = allValues?.rooms?.map((room: any) => room) || [];
    setRooms(selectedRooms);
    selectedHotels([]);
    setCityIndex(1);
  };
  return (
    <TravellerSelectorsWrapper>
      <div className="traveller-selectors-title">
        <FormattedMessage
          description="How many travelling"
          defaultMessage="How many travelling"
          id="PkASeu"
        />
      </div>
      <div className="traveller-selectors-list">
        <Form
          name="horizontal_login"
          layout="vertical"
          form={form}
          onValuesChange={handleOnValuesChange}
          initialValues={{
            numberOfRooms: "1",
            rooms: [{ roomType: "double", numberChilds: 0 }],
          }}
        >
          <SelectField
            name="numberOfRooms"
            options={roomsNumber}
            label="Αριθμός δωματίων"
            placeholder="Αριθμός δωματίων"
            onChange={handleChangeNumbersRooms}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          />
          <Form.List name="rooms">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space
                    className="full-width traveller-selectors-room"
                    key={index}
                    align={index === 0 ? "center" : "baseline"}
                    direction="horizontal"
                  >
                    <div className="traveller-selectors-des">
                      Δωμάτιο {index + 1}
                    </div>
                    <SelectField
                      name={[index, "roomType"]}
                      options={roomType(isMobile)}
                      label="Ενήλικας(ες) 12+ Χρόνων"
                      placeholder="Ενήλικας(ες)"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    />
                    <SelectField
                      name={[index, "numberChilds"]}
                      options={childernNumber}
                      label="Παιδί(α) 2-11 Χρόνων"
                      placeholder="Παιδί(α)"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    />
                  </Space>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </div>
    </TravellerSelectorsWrapper>
  );
};
