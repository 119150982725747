import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Many, orderBy } from "lodash";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { userFirestoreCollectionOnce } from "../../../hooks/useFirestoreCollection";
import {
  fetchCategory81497FromFirestore,
  fetchCity83431FromFirestore,
  fetchHotel28355FromFirestore,
  fetchPackagesFromFirestore,
} from "../../../firestore/firestoreService";
import { listenToPackages } from "../../../redux/data/package/packageActions";
import {
  ItemNotFound,
  Loader,
  PackageItemList,
  PackageListFrontEndWrapper,
} from "../../molecules";
import { listenToCategories } from "../../../redux/data/category/categoryActions";
import { listenToHotels } from "../../../redux/data/hotel/hotelActions";
import PackageFilter from "../../molecules/Packages/PackageFilter";
import { listenToCities } from "../../../redux/data/city/cityActions";

const defaultSorting: any = {
  type: "sorting",
  name: "minPricePerAdult",
  order: "asc",
  opr: "asc",
};

export const PackageList: React.FC<{
  params: any;
  partner?: string;
}> = ({ params, partner }) => {
  const { packages } = useSelector((state: any) => state.packages);
  const { cities } = useSelector((state: any) => state.cities);
  const { categories } = useSelector((state: any) => state.categories);

  const { hotels } = useSelector((state: any) => state.hotels);
  const { loading, error } = useSelector((state: any) => state.async);
  const [showFilter, setShowFilters] = useState(true);
  const [sorting, setSorting] = useState<any>(defaultSorting);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const dispatch = useDispatch();
  userFirestoreCollectionOnce({
    query: () =>
      fetchPackagesFromFirestore([
        {
          type: "filter",
          name: "status",
          opr: "==",
          value: true,
        },
        (partner && {
          type: "filter",
          name: "partnerId",
          opr: "array-contains",
          value: partner,
        }) ||
          null,
        // ...convertParamsToQueryConfig(params),
      ]),
    data: (data: any) => listenToPackages(data),
    deps: [dispatch],
  });
  userFirestoreCollectionOnce({
    query: () =>
      fetchCategory81497FromFirestore([
        {
          type: "filter",
          name: "hasPackages",
          opr: "==",
          value: true,
        },
      ]),
    data: (data: any) => listenToCategories(orderBy(data, ["name"])),
    deps: [dispatch],
  });
  userFirestoreCollectionOnce({
    query: () =>
      fetchHotel28355FromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
        {
          type: "filter",
          name: "topHotel",
          opr: "==",
          value: true,
        },
      ]),
    data: listenToHotels,
    deps: [dispatch],
  });
  userFirestoreCollectionOnce({
    query: () =>
      fetchCity83431FromFirestore([
        {
          type: "filter",
          name: "hasPackages",
          opr: "==",
          value: true,
        },
      ]),
    data: (data: any) => listenToCities(orderBy(data, ["name"])),
    deps: [dispatch],
  });
  const handelShowFilter = () => {
    setShowFilters((v) => !v);
  };
  const handelSortingChange = (value: string) => {
    const [name, direction]: [string, Many<boolean | "asc" | "desc">] =
      Object.values(value.split("-")) as [
        string,
        Many<boolean | "asc" | "desc">,
      ];
    setSorting({ type: "sorting", name, opr: "", order: direction });
  };
  React.useEffect(() => {
    if (!isTabletOrMobile) {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }
  }, [isTabletOrMobile]);
  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/"
        redirectBtnText="Back to Home Page"
        itemType="City"
        itemId={"sd"}
      />
    );
  }
  // console.log(packages);
  return (
    <PackageListFrontEndWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Poupas for holidays - Packages List`}</title>
      </Helmet>
      <div className="package-list-frontend">
        <div className="package-list-frontend-body">
          <div
            className={classNames("package-list-frontend-left", {
              hide: showFilter,
            })}
          >
            <PackageFilter
              cities={cities}
              categories={categories}
              isTabletOrMobile={isTabletOrMobile}
              showFilter={handelShowFilter}
              params={params}
              hotels={hotels}
              url={"packages"}
            />
          </div>

          <PackageItemList
            travelPackages={packages}
            sorting={sorting}
            isTabletOrMobile={isTabletOrMobile}
            isMobile={isMobile}
            onSort={handelSortingChange}
            onShowFilter={handelShowFilter}
            partner={partner}
          />
        </div>
      </div>
    </PackageListFrontEndWrapper>
  );
};
