import React from "react";
import { find } from "lodash";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { CheckOutlined } from "@ant-design/icons";
import { IDestination, IPackageHotelPublic } from "../../../common/typings";
import { Collapse, Divider } from "../../atoms";
import { IPackage } from "../../../common/types";
import { PackageHotelItem } from "../../molecules";
import { formatReadableCity } from "../../../common/util/util";
import { PackageHotelsWrapper } from "./PackageViewFrontEnd.style";
import { PackageHotelView } from "./PackageHotelView";

export interface IPackageHotelsFrontendProps {
  destinations: IDestination[];
  hotels: IPackageHotelPublic[];
  selectedHotels: IPackageHotelPublic[];
  selectHotel: (hotel: IPackageHotelPublic) => void;
  isTabletOrMobile: boolean;
  isMobile: boolean;
  discount: number;
  selectedRooms: any[];
  travelPackage: IPackage;
  cityIndex: number;
  setCityIndex: (id: number) => void;
}
export const PackageHotels: React.FC<IPackageHotelsFrontendProps> = ({
  destinations,
  selectHotel,
  selectedHotels,
  hotels,
  isTabletOrMobile,
  isMobile,
  discount,
  selectedRooms,
  travelPackage,
  cityIndex,
  setCityIndex,
}) => {
  const [viewedHotel, setViewedHotel] = React.useState<IPackageHotelPublic>();
  const [visible, setVisible] = React.useState(false);
  const showHotel = (currentHotel: IPackageHotelPublic) => {
    setVisible(true);
    setViewedHotel(currentHotel);
  };
  const hideViewedHotel = () => {
    setVisible(false);
  };
  const handleChangeCity = (v: any) => {
    setCityIndex(v);
  };
  const getCityHotels = (cityId: string) =>
    hotels.filter((hotel: IPackageHotelPublic) => hotel.cityId === cityId);
  return (
    <PackageHotelsWrapper>
      <div className="package-hotels-title">
        <FormattedMessage
          description="Hotels"
          defaultMessage="Hotels"
          id="qrGoSh"
        />
      </div>
      <div className="package-hotels-des">
        Οι αναγραφόμενες τιμές είναι οι τελικές τιμές ανά άτομο και
        συμπεριλαμβάνουν όλες τις αναγραφόμενες Υπηρεσίες του Πακέτου.
      </div>
      <Collapse activeKey={[cityIndex]} accordion onChange={handleChangeCity}>
        {destinations.map(
          ({ cityId, city, checkInDate, checkOutDate }, index) => (
            <Collapse.Panel
              className="package-hotels-list"
              key={index + 1}
              header={
                <Divider>
                  <div className="package-hotels-title">
                    {formatReadableCity(city)} (
                    {moment(checkInDate).format("DD/MM/YYYY")}-{" "}
                    {moment(checkOutDate).format("DD/MM/YYYY")})
                    {!find(selectedHotels, ["cityId", cityId]) && (
                      <div style={{ fontSize: 14, fontWeight: 300 }}>
                        Παρακαλώ επιλέξτε το Ξενοδοχείο της προτίμησης σας.
                      </div>
                    )}
                  </div>
                </Divider>
              }
              extra={
                !!find(selectedHotels, ["cityId", cityId]) && (
                  <CheckOutlined style={{ color: "green", fontSize: 24 }} />
                )
              }
            >
              <div className="package-hotels-prices">
                {getCityHotels(cityId).map(
                  (packageHotel: IPackageHotelPublic) => (
                    <PackageHotelItem
                      key={packageHotel.id}
                      packageHotel={packageHotel}
                      selectHotel={selectHotel}
                      multipleCity={index >= 1}
                      isSelectedHotel={
                        !!find(selectedHotels, ["id", packageHotel.id])
                      }
                      showHotel={showHotel}
                      isTabletOrMobile={isTabletOrMobile}
                      discount={discount}
                      selectedRooms={selectedRooms}
                      travelPackage={travelPackage}
                      cityDisplayName={`${formatReadableCity(city)} (
                              ${moment(checkInDate).format("DD/MM/YYYY")}-
                        ${moment(checkOutDate).format("DD/MM/YYYY")})`}
                      setCityView={() =>
                        handleChangeCity(
                          destinations.length > index ? index + 2 : index + 1,
                        )
                      }
                    />
                  ),
                )}
              </div>
            </Collapse.Panel>
          ),
        )}
      </Collapse>
      {/* {destinations.map(({ cityId, city, checkInDate, checkOutDate }) => (
        <div className="package-hotels-list" key={cityId}>
          <Divider>
            <div className="package-hotels-title">
              {formatReadableCity(city)} (
              {moment(checkInDate).format("DD/MM/YYYY")}-{" "}
              {moment(checkOutDate).format("DD/MM/YYYY")})
            </div>
          </Divider>
          <div className="package-hotels-prices">
            {getCityHotels(cityId).map((packageHotel: IPackageHotelPublic) => (
              <PackageHotelItem
                key={packageHotel.id}
                packageHotel={packageHotel}
                selectHotel={selectHotel}
                isSelectedHotel={
                  !!find(selectedHotels, ["id", packageHotel.id])
                }
                showHotel={showHotel}
                isTabletOrMobile={isTabletOrMobile}
                discount={discount}
                selectedRooms={selectedRooms}
                travelPackage={travelPackage}
              />
            ))}
          </div>
        </div>
      ))} */}
      <PackageHotelView
        viewedHotel={viewedHotel}
        mealsType={viewedHotel?.mealsType || ""}
        visible={visible}
        onClose={hideViewedHotel}
        isMobile={isMobile}
      />
    </PackageHotelsWrapper>
  );
};
