import styled from "styled-components";
export const PackageViewFrontEndWrapper = styled.div`
  background-color: #f6f8f9;
  padding: 40px 20px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  .package-view-frontend {
    &-body {
      display: flex;
      margin: auto;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
    }
    &-left {
      overflow-y: auto;
      flex: 1;
      padding: 30px;
      @media only screen and (max-width: 600px) {
        padding: 0px;
      }
      /* margin: 0 auto;
      max-width: 1000px; */
    }
    &-right {
      background-color: #fff;
      box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
      padding: 0px;
      width: 400px;
      display: flex;
      flex-direction: column;
      transform: translateX(0);
      transition: all 0.35s ease;
      @media only screen and (max-width: 1200px) {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        bottom: 0;
      }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }
    &-booking-details {
      background-color: #fff;
      position: absolute;
      bottom: 0;
      padding: 0px;
      left: 0;
      right: 0;
      box-shadow: 0px -1px 6px 1px rgb(64 87 109 / 22%);
      z-index: 80;
      text-align: center;
      border-top: 1px solid #cfcfcf;
      padding: 10px;
      &-btn {
        height: 80px;
        width: 100%;

        font-size: 15px;
        font-family: "Avenir";
        padding: 0;
        margin: 0;
        &-btn {
          background-color: #1d3bf8;
          height: 64px;
          width: 100%;
          color: #fff;
          font-size: 18px;
          font-family: "Avenir";
          font-weight: 900;
        }
        &-label {
          span {
            font-weight: 900;
          }
        }
      }
      &-discount-text {
        color: #ffc926;
        font-size: 16px;
        font-weight: bold;
        font-family: "Avenir";
        padding: 10px 20px;
        align-items: center;
        background-color: #111;
        margin: 5px 10px;
      }
    }
  }
  .hide {
    transform: translateX(100%);
    width: 0;
  }
`;

export const PackageDetailsWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
  margin-bottom: 40px;
  .package-details {
    &-tabs {
      .ant-tabs-nav {
        padding: 30px 50px 0;
        @media only screen and (max-width: 600px) {
          padding: 10px 5px 0;
        }
      }
      .ant-tabs-tabpane {
        padding: 30px 50px;
        @media only screen and (max-width: 600px) {
          padding: 10px 10px;
        }
      }
    }
  }
`;
export const PackageFlightsWrapper = styled.div`
  margin-bottom: 40px;
  padding: 0 10px;
  .package-flights {
    &-title {
      color: #22335d;
      font-size: 18px;
      font-weight: 900;
      font-family: "Avenir";
    }
    &-list {
      margin-top: 24px;
      background-color: #fff;
      box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
      padding: 40px 25px;
      @media (max-width: 600px) {
        padding: 10px 25px;
      }
    }
  }
`;

export const PackageCruisesWrapper = styled.div`
  margin-bottom: 40px;
  padding: 0 10px;
  .package-cruises {
    &-title {
      color: #22335d;
      font-size: 18px;
      font-weight: 900;
      font-family: "Avenir";
    }
    &-des {
      color: #485b7c;
      font-size: 16px;
      font-weight: 300;
      font-family: "Avenir";
    }
    &-list {
      margin-top: 24px;
      background-color: #fff;
      box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
      padding: 40px 25px;
    }
  }
`;

export const PackageHotelsWrapper = styled.div`
  margin-bottom: 40px;
  padding: 0 10px;
  .ant-collapse > .ant-collapse-item > .ant-collapse {
    &-header {
      align-items: center;
    }
  }
  .ant-collapse-arrow {
    font-size: 24px !important;
  }
  .package-hotels {
    &-title {
      color: #22335d;
      font-size: 18px;
      font-weight: 900;
      font-family: "Avenir";
      white-space: break-spaces;
    }
    &-des {
      color: #485b7c;
      font-size: 16px;
      font-weight: 300;
      font-family: "Avenir";
    }
    &-list {
      overflow: hidden;
      /* margin-top: 24px;
      background-color: #fff;
      box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
      padding: 40px 25px; */
    }
    &-prices {
      overflow-y: auto;
    }
  }
`;

export const TravellerSelectorsWrapper = styled.div`
  margin-bottom: 30px;
  padding: 0 10px;
  .traveller-selectors {
    &-title {
      color: #22335d;
      font-size: 18px;
      font-weight: 900;
      font-family: "Avenir";
    }
    &-list {
      margin-top: 24px;
      background-color: #fff;
      box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
      padding: 40px 25px;
    }
    &-des {
      color: #485b7c;
      font-size: 16px;
      font-weight: 300;
      font-family: "Avenir";
    }
    &-room {
      &:not(:last-child) {
        border-bottom: 1px solid #e9eef2;
        margin-bottom: 20px;
      }
    }
  }
`;

export const LuggageSelectorsWrapper = styled.div`
  margin-bottom: 30px;
  padding: 0 10px;
  .luggage-selectors {
    &-title {
      color: #22335d;
      font-size: 18px;
      font-weight: 900;
      font-family: "Avenir";
    }
    &-list {
      margin-top: 24px;
      background-color: #fff;
      box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
      padding: 40px 25px;
    }
    &-des {
      color: #485b7c;
      font-size: 16px;
      font-weight: 300;
      font-family: "Avenir";
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        border-bottom: 1px solid #e9eef2;
        margin-bottom: 20px;
      }
      &-selector {
        flex: 1;
      }
      &-totalPrice {
        font-size: 16px;
        font-weight: 500;
        color: #22335d;
        width: 100px;
        text-align: right;
        padding-right: 10px;
      }
    }
    &-totalPrice {
      font-size: 21px;
      font-weight: 500;
      color: #22335d;
      width: 100%;
      text-align: right;
      padding-right: 10px;
      span {
        font-size: 16px;
        padding-right: 10px;
      }
    }
  }
`;
